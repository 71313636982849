import React, { useEffect } from "react"
import { graphql, Link} from "gatsby"


import {Blob} from '../components/images';
import SEO from "../components/seo"
import { MenuTrigger,
   Social, Brand, LocationIndicator , Button} from "../components/micro";
import { Menu, Layout  } from "../components";

import useMenu from '../hooks/useMenu';

import '../styles/project-page.scss';


// Fonts
import Lato from './../images/svg/Lato.svg';
import Playfair from './../images/svg/Playfair.svg';
import Poppins from './../images/svg/Poppins.svg';
import Cinzel from './../images/svg/Cinzel.svg';
import Montserrat from './../images/svg/Montserrat.svg';
import Proxima from './../images/svg/Proxima.svg';
import DMSerif from './../images/svg/Dm.svg'



const ProjectPage = ({data})=>{
    const {projectJson: project} = data;
    const {title, subtitle, excerpt, colours, involvement, featuredImage, projectImages, link, internalLink, fonts } = project;
    
    const { isMenuVisible, toggleMenuVisibility } = useMenu();
    
    
    useEffect(() => {


    
        const fadeOptions = {
          threshold: '1',
          rootMargin: '0px 0px -10px 0px'
        }
    
        const fadeOnScroll = new IntersectionObserver( function( entries, fadeOnScroll ){
            entries.forEach( entry => {
              if( ! entry.isIntersecting ){
                return;
              } else {
                entry.target.style.animation = `fade-in 500ms ${ entry.target.dataset.delay } forwards ease-out`
                entry.target.classList.add( 'appear' );
                fadeOnScroll.unobserve( entry.target );
              }
            })
        }, fadeOptions );
    
        const elementsToObserve = document.querySelectorAll( '.fade-in' );
    
        if( elementsToObserve.length ){
          elementsToObserve.forEach( element => {
            fadeOnScroll.observe( element )
          })
        }
    
        
        return () => {
          
        }
      }, [  project ])

    return(
        <>
        <Layout>
        <SEO title={ title }/>
      <div className={`project-page section`} data-name={ title } data-anchor={ title } >
          <div className="project-page__header">
              <h1 className="project-page__title fade-in">{title}</h1>
              <p className={"project-page__body fade-in"}> {excerpt}</p>
          </div>
          <div className="project-page__content">
     
        

{ true && (
  <div className="project-page__fonts">
    {
      fonts.includes('cinzel') && <Cinzel />
    }
    {
      fonts.includes('playfair') && <Playfair />
    }
    {
      fonts.includes('dm-serif') && <DMSerif />
    }
    {
      fonts.includes('lato') && <Lato />
    }
    {
      fonts.includes('poppins') && <Poppins />
    }
    {
      fonts.includes('montserrat') && <Montserrat />
    }
    {
      fonts.includes('proxima') && <Proxima />
    }
 
  </div>  
)}


<div className={'project-page__colors'} >
  {
      colours && colours.map( 
          ( colour, idx ) => (
              <div key={ idx } data-color={ colour } className="project-page__color" style={{backgroundColor: `${colour}`}}> </div>
          )
      )
  }

</div>

<div className={'project-display'}>

<div className={'project-display__cell fade-in'}>
  <img src={`${featuredImage.publicURL}`} className={'project-display__img'}/>
</div>
<div className={'project-display__cell'}>
  <h2 className={'project-display__subtitle'}>
      Responsibilities
  </h2>
  <ul className={'project-display__list fade-in'}>
      {involvement.map(i => (<li key={i} >{i}</li>))}
  </ul>


  {internalLink && (
  <a href={ internalLink } target="_blank" className="project-display__btn secondary fade-in">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='project-display__svg'>
          <path d="M488.727 0H302.545c-12.853 0-23.273 10.42-23.273 23.273s10.42 23.273 23.273 23.273h129.997L192.999 286.09c-9.087 9.087-9.087 23.823 0 32.912a23.2 23.2 0 0016.455 6.816 23.195 23.195 0 0016.455-6.817L465.455 79.458v129.997c0 12.853 10.42 23.273 23.273 23.273s23.273-10.42 23.273-23.273V23.273C512 10.42 501.58 0 488.727 0z" />
          <path d="M395.636 232.727c-12.853 0-23.273 10.42-23.273 23.273v209.455H46.545V139.636H256c12.853 0 23.273-10.42 23.273-23.273S268.853 93.091 256 93.091H23.273C10.42 93.091 0 103.511 0 116.364v372.364C0 501.58 10.42 512 23.273 512h372.364c12.853 0 23.273-10.42 23.273-23.273V256c-.001-12.853-10.421-23.273-23.274-23.273z" />
      </svg>
      Static Files
      </a>)}
  {link && (

      <a href={link} target="_blank" className="project-display__btn primary fade-in"> 
      Live Website
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='project-display__svg'>
          <path d="M488.727 0H302.545c-12.853 0-23.273 10.42-23.273 23.273s10.42 23.273 23.273 23.273h129.997L192.999 286.09c-9.087 9.087-9.087 23.823 0 32.912a23.2 23.2 0 0016.455 6.816 23.195 23.195 0 0016.455-6.817L465.455 79.458v129.997c0 12.853 10.42 23.273 23.273 23.273s23.273-10.42 23.273-23.273V23.273C512 10.42 501.58 0 488.727 0z" />
          <path d="M395.636 232.727c-12.853 0-23.273 10.42-23.273 23.273v209.455H46.545V139.636H256c12.853 0 23.273-10.42 23.273-23.273S268.853 93.091 256 93.091H23.273C10.42 93.091 0 103.511 0 116.364v372.364C0 501.58 10.42 512 23.273 512h372.364c12.853 0 23.273-10.42 23.273-23.273V256c-.001-12.853-10.421-23.273-23.274-23.273z" />
      </svg>
      
   </a>
  )}
  
 
</div>

{projectImages.map( ( img, idx )  => (
  <div className={'project-display__cell fade-in'} key={ idx }>
      <img src={`${img.publicURL}`} className={'project-display__img'}/>
  </div>
))}

 
</div>

<div style={{ textAlign: 'center'}}>
              <Button
                color="yellow"
                slug="/"
                >
                    Go Home
                </Button>

          </div>


{/* end of content */}
  </div>


         


      </div>

      </Layout>

      </>

    )
}

export const query = graphql`
    query($slug: String!){
        projectJson(slug: {eq: $slug}){
            title
            subtitle
            colours
            date
            fonts
            featuredImage{
                publicURL
            }
            projectImages{
                publicURL
            }
            blobFill
            involvement
            excerpt
            link
            internalLink
        }
    }
    
`





export default ProjectPage;